.theme-btn {
	color: $white;
	display: inline-block;
	font-weight: 500;
	padding: 20px 35px;
	border: 0;
	text-transform: capitalize;
	-webkit-backface-visibility: hidden;
	z-index: 1;
	position: relative;
	font-size: 15px;
	cursor: pointer;
	background: #006436;
	// background: linear-gradient(90deg, rgba(45, 73, 189, 1) 0%, rgba(222, 32, 69, 1) 100%);
	font-family: $heading-font;
	border-radius: 5px;


	&:after {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(90deg, #cf122b 0%, #006436 100%);
		transition: opacity 0.5s ease-out;
		opacity: 0;
		z-index: -1;
		border-radius: 5px;
	}

	&:hover,
	&:focus,
	&:active {
		color: $white;
	}

	&:hover:after {
		opacity: 1;
	}

	@include media-query(991px) {
		font-size: 18px;
		font-size: calc-rem-value(18);
	}

	@include media-query(767px) {
		padding: 12px 18px;
		font-size: 14px;
	}
}

.theme-btn2 {
	color: $white;
	display: inline-block;
	font-weight: 500;
	padding: 20px 35px;
	border: 0;
	text-transform: capitalize;
	-webkit-backface-visibility: hidden;
	z-index: 1;
	position: relative;
	font-size: 15px;
	cursor: pointer;
	background: #000000;
	// background: linear-gradient(90deg, rgba(45, 73, 189, 1) 0%, rgba(222, 32, 69, 1) 100%);
	font-family: $heading-font;
	border-radius: 5px;


	&:after {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(90deg, #cf122b 0%, #006436 100%);
		transition: opacity 0.5s ease-out;
		opacity: 0;
		z-index: -1;
		border-radius: 5px;
	}

	&:hover,
	&:focus,
	&:active {
		color: $white;
	}

	&:hover:after {
		opacity: 1;
	}

	@include media-query(991px) {
		font-size: 18px;
		font-size: calc-rem-value(18);
	}

	@include media-query(767px) {
		padding: 12px 18px;
		font-size: 14px;
	}
}





.theme-btn-s2 {
	background: $white;
	color: $theme-primary-color-s2;
	display: inline-block;
	padding: 12px 22px;
	border: 1px solid $theme-primary-color-s2;
	border-radius: 55px;
	text-transform: capitalize;
	z-index: 1;
	position: relative;
	font-family: $heading-font;

	&:after {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 55px;
		@include linear-gradient-bg(left, #00ded8, #00a7d5);
		transition: opacity 0.5s ease-out;
		opacity: 0;
		z-index: -1;
	}

	@include media-query(991px) {
		font-size: 14px;
		font-size: calc-rem-value(14);
	}

	&:hover,
	&:focus,
	&:active {
		background: darken($theme-primary-color-s2, 5%);
		// @include linear-gradient-bg(left, #00ded8, #00a7d5);
		color: $white;
	}

	&:hover:after {
		opacity: 1;
	}

	@include media-query(767px) {
		padding: 12px 18px;
		font-size: 13px;
	}
}

.theme-btn-s3 {
	color: $white;
	display: inline-block;
	padding: 18px 35px;
	text-transform: capitalize;
	z-index: 1;
	position: relative;
	font-family: $heading-font;
	font-size: 14px;
	font-weight: 600;
	background: transparent;
	color: $white;
	border: 1px solid $theme-primary-color-s3;
	transition: all .4s ease-in-out;

	@include media-query(991px) {
		font-size: 14px;
		font-size: calc-rem-value(14);
	}

	&:hover,
	&:focus,
	&:active {
		border: 1px solid $theme-primary-color;
		&:before {
			width: 100%;
		}
	}

	&:before {
		position: absolute;
		content: '';
		width: 0;
		background: $theme-primary-color;
		top: 0;
		left: 0;
		height: 100%;
		z-index: -1;
		transition: all .4s ease-in-out;
	}

	&:hover:after {
		opacity: 1;
	}

	@include media-query(767px) {
		padding: 12px 18px;
		font-size: 13px;
	}
}


.view-cart-btn {
	@extend .theme-btn;
	display: block;
	margin-top: 15px;
	border-radius: 5px;
	padding: 10px 45px;



	&:after {
		display: none;
	}

	&:hover {
		background-color: darken($theme-primary-color, 8%);
	}

	&.s1 {
		background: #ddd;

		&:hover {
			background-color: darken(#ddd, 8%);
		}
	}

}